<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<!-- <div style="width: 57%">
          <el-input
            class="hunt"
            v-model="input"
            placeholder="请输入"
            size="large"
          />
          <el-button style="margin-left: 10px" type="primary" size="large"
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div> -->
				<div>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="standard=true"><el-icon
							class="cancelgu">
							<Setting />
						</el-icon>设置</el-button>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="append"><el-icon
							class="cancelgu">
							<Plus />
						</el-icon>添加</el-button>
				</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
				<!-- 类型 -->
				<el-table-column :show-overflow-tooltip='true' align="center" label="类型名称" width="250" prop="name" />
				<!-- 图片 -->
				<el-table-column label="图片" prop="imageUrl" width="350" align="center">
					<template #default="scope">
						<el-image class="table-td-thumb" :src="scope.row.imageUrl"
							:preview-src-list="[scope.row.imageUrl]" preview-teleported="true">
						</el-image>
					</template>
				</el-table-column>
				<!-- 排序 -->
				<el-table-column :show-overflow-tooltip='true' align="center" label="排序" prop="sort" />
				<!-- 时间 -->
				<el-table-column :show-overflow-tooltip='true' label="创建时间" prop="createTime" align="center"
					:formatter="tiems">
				</el-table-column>
				<el-table-column label="操作" align="center" width="340">
					<template #default="scope">
						<el-button size="default" type="success" @click="handleEdit(scope.row.modelId)" class="green">
							<el-icon class="cancel">
								<Setting />
							</el-icon>
							管 理</el-button>
						<el-button size="default" @click="copyreader(scope.row.modelId)" class="bulur"><el-icon
								class="cancel">
								<EditPen />
							</el-icon>编辑</el-button>
						<el-button size="default" @click="expurgate(scope.row.modelId)" class="red"><el-icon
								class="cancel">
								<Delete />
							</el-icon>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<!-- 一页时候隐藏 hide-on-single-page="true" -->
			<!-- <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage" 
        :page-size="pagesize"
        :total="filterTableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      /> -->
		</el-card>
		<!-- 删除按钮弹窗 -->
		<el-dialog v-model="remove" title="类型删除" width="30%" center>
			<div class="labor">
				<div class="sure">确定删除该类型吗？</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
					<el-button class="dsa" @click="remove=false" size="large">取消</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 设置弹窗 -->
		<el-dialog v-model="standard" title="设置" width="30%" center>
			<div class="labor">
				<div class="designation">提示</div>
				<div><el-input size="large" v-model="price" placeholder="请输入提示内容" /></div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button class="asd" size="large" type="primary" @click="right">确定</el-button>
					<el-button class="dsa" size="large" @click="standard=false">取消</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import {
		Delete,
		EditPen,
		Plus,
		Setting
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		XJlist,
		XJdelete,
		XJhint,
		XJdescribe
	} from "../utils/api"
	import {
		ref,
		onMounted,
		onActivated
	} from 'vue'
	import moments from 'moment'
	import qs from 'qs'
	import {
		ElMessage
	} from "element-plus";
	export default {
		name: "advanced",
		setup() {
			const route = useRoute();
			// 加载中提示
			let texts = ref('加载中')
			// 修改确认
			let right = () => {
				let data = {
					tipsContent: price.value
				}
				XJdescribe(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						ElMessage({
							message: '设置成功',
							type: "success",
							center: true,
						});
						standard.value = false
						hint()
					}
				})
				console.log(price.value)
			}
			// 提示内容
			let price = ref('')
			// 获取当前提示内容
			let hint = () => {
				XJhint().then((res) => {
					// console.log(res)
					if (res.data.code == 0) {
						price.value = res.data.data
					}
				})
			}
			// 提示弹窗
			let standard = ref(false)
			// 确定删除
			let affirm = () => {
				let data = {
					modelId: genre.value
				}
				XJdelete(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						ElMessage({
							message: '删除成功',
							type: "success",
							center: true,
						});
						remove.value = false
						list()
					} else {
						ElMessage({
							message: res.data.msg,
							type: "warning",
							center: true,
						});
						remove.value = false
					}
				})
			}
			// 删除弹窗
			let remove = ref(false)
			// 获取删除类型id
			let genre = ref(null)
			// 列表内容删除
			let expurgate = (id) => {
				remove.value = true
				genre.value = id
				console.log(id)
			}
			// 时间转换
			let tiems = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format().substring(0, 10)
				}
			}
			// 获取典型列表
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.userId
				if (translate.value == 1) {
					texts.value = '加载中'
					// branches.value=6
					// currentPage.value=1
					list()
					hint()
				}
			})
			onMounted(() => {
				list()
				hint()
			})
			let list = () => {
				XJlist().then((res) => {
					filterTableData.value = res.data.data.modelList
					if (filterTableData.value.length == 0) {
						texts.value = '暂无数据'
						console.log('空了')
					}
					console.log(res)
				})
			}
			// 管理跳转
			const handleEdit = (id) => {
				console.log(id)
				sessionStorage.setItem('modesids', id)
				// router.push('/xjmanage')
				router.push({
					name: 'xjmanage',
					params: {
						UserId: 1
					}
				});
			}
			// 编辑跳转
			const copyreader = (id) => {
				console.log(id)
				sessionStorage.setItem('mode', id)
				router.push({
					name: 'xjedit',
					params: {
						UserId: 1
					}
				});
			}
			// 路由跳转
			const router = useRouter()
			// 头部添加跳转
			let append = () => {
				router.push('/xjadd')
			}
			// 列表数据循环
			const filterTableData = ref([])
			return {
				texts,
				// 修改确认
				right,
				// 默认提示内容
				price,
				// 设置弹窗
				standard,
				// 确定删除
				affirm,
				// 删除弹窗
				remove,
				// 删除
				expurgate,
				// 时间转换
				tiems,
				// 管理跳转
				handleEdit,
				// 编辑跳转
				copyreader,
				// 头部组织添加跳转
				append,
				// 循环列表数据
				filterTableData
			};
		},
		components: {
			Delete,
			EditPen,
			Plus,
			Setting
		},
	};
</script>
<style lang="scss" scoped>
	.designation {
		margin-right: 10px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	::v-deep .el-textarea__inner {
		height: 110px;
	}

	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.el-button+.el-button {
		margin-left: 0px;
	}

	.green {
		color: #67c23a;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
		--el-button-active-bg-color: none;
	}

	.green:hover {
		color: #b3e19d;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 36.5%;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
		display: flex;
		justify-content: flex-end;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	::v-deep .el-scrollbar__thumb {
		display: none !important;
	}
</style>
<style>
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>